.ticket {
    font-family: "Lucida-Console-Regular";
}

.double-width {
    -webkit-transform: scaleX(2);
    -moz-transform: scaleX(2);
    -ms-transform: scaleX(2);
    -o-transform: scaleX(2);
    transform: scaleX(2);
}

.double-width-and-height {
    -webkit-transform: scaleX(2) scaleY(2);
    -moz-transform: scaleX(2) scaleY(2);
    -ms-transform: scaleX(2) scaleY(2);
    -o-transform: scaleX(2) scaleY(2);
    transform: scaleX(2) scaleY(2);
}

.body-of-text {
    font-size: 12px;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
}

.google-badge {
    width: 133.5px;
}

/*.serrated {
    width: 75%;
    padding: 10px 10px;
    border: 10px solid transparent;
    border-width: 10px 0;
    background-color: whitesmoke;
    background-color: hsla(0, 0%, 0%, 0);
    background-image:
            linear-gradient(#F5F5F5, #F5F5F5),
            linear-gradient(to bottom right, transparent 50.5%, whitesmoke 50.5%),
            linear-gradient(to bottom left, transparent 50.5%, whitesmoke 50.5%),
            linear-gradient(to top right, transparent 50.5%, whitesmoke 50.5%),
            linear-gradient(to top left, transparent 50.5%, whitesmoke 50.5%);
    background-repeat: repeat, repeat-x, repeat-x, repeat-x, repeat-x;
    background-position: 0 0, 10px 0, 10px 0, 10px 100%, 10px 100%;
    background-size: auto auto, 20px 20px, 20px 20px, 20px 20px, 20px 20px;
    background-clip: padding-box, border-box, border-box, border-box, border-box;
    background-origin: padding-box, border-box, border-box, border-box, border-box;
}*/

