@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {
    @font-face {
        font-family: "Lucida-Console-Regular";
        src: local("Lucida-Console-Regular"), url("../fonts/Lucida-Console-Regular.ttf") format("truetype");
        font-weight: normal;
    }
}
